import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout/layout';
import BannerComponent from '../components/banner/banner';
import WineCard from '../components/wine-card/wine-card';
import WineGrid from '../components/wine-grid/wine-grid';
import { SectionTitle } from '../components/section-title';
import { container } from '../components/layout/layout.styles';

import cltImg from '../images/cl-tinto-prod.jpg'
import clbImg from '../images/cl-blanco2018-prod.jpg'
import cldImg from '../images/cl-dulce2018-prod.jpg'
import m116Img from '../images/116-tinto-prod.jpg'
import clsrImg from '../images/cl-spumanterosado-prod.jpg'


const IndexPage = () => (
  <Layout>
    {/* <BannerComponent /> */}
    <div className={`${container}`}>
      <SectionTitle>
        <h2>NUESTROS VINOS</h2>
        <p>De la tierra &mdash; de pura tradición y dedicación.</p>
      </SectionTitle>
      
      <WineGrid>
        <WineCard title="Corazón Loco Tinto" subTitle="Vino tinto joven" imgSrc={cltImg}></WineCard>
        <WineCard title="Corazón Loco Blanco" subTitle="Vino blanco joven" imgSrc={clbImg}></WineCard>
        <WineCard title="Minuto 116 Tinto" subTitle="Vino tinto joven" imgSrc={m116Img}></WineCard>
        <WineCard title="Dulce Corazón Blanco" subTitle="Vino semi-dulce blanco" imgSrc={cldImg}></WineCard>
        <WineCard title="Corazón Spumante Rosado" subTitle="Vino espumoso rosado" imgSrc={clsrImg}></WineCard>
      </WineGrid>
    </div>
  </Layout>
)

export default IndexPage
