import styled from '@emotion/styled'
import { breakpoints, fonts } from '../variables'

export const Wrap = styled.div`
  background: white;
  margin: 0;
`

export const Head = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  height: auto;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1280px;
  padding: 2rem;

  a {
    line-height: 1;
  }

  img {
    height: 50px;
    margin: 0;
  }
`

export const Nav = styled.ul`
  align-items: center;
  font-size: 1.3rem;
  margin: 0;
  padding: 0;

  a {
    color: #5B131A;
    font-family: ${fonts.tertiary};
    font-size: 2rem;
    font-weight: 300;
    letter-spacing: .2em;
    text-decoration: none;

    &:not(:first-of-type) {
      margin-left: 2rem;
    }
  }
`
