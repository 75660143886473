import styled from '@emotion/styled'

export const WineGrid = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 2rem auto;
`;