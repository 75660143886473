import React from 'react'
import { WineCard } from './wine-card.styles'

const WineCardComponent = ({ title, subTitle, imgSrc}) => (
  <WineCard>
    <img src={imgSrc}/>
    <h4>{title}</h4>
    <p>{subTitle}</p>
  </WineCard>
)

export default WineCardComponent