import styled from '@emotion/styled'
import { breakpoints } from './variables'

const primaryColor = '#59151D';
const primaryLight = '#AB9EA5';

export const SectionTitle = styled.div`
  display: block;
  margin: 2em auto;
  padding: 0 2rem;
  position: relative;
  text-align: center;
  width: 100%;
  
  @media (${breakpoints.small}) {
    margin: 10rem auto;
  }

  h2 {
    color: ${primaryColor};
    font-size: 3.2rem;
    letter-spacing: .1ex;
    margin-bottom: .5rem;
    
    @media (${breakpoints.small}) {
      font-size: 4.8rem;
    }
  }

  p {
    color: ${primaryLight};
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: .1em;
    text-transform: uppercase;

    @media (${breakpoints.small}) {
      font-size: 1.5rem;
    }
  }

  a {
    border-bottom: 3px solid ${primaryColor};
    font-weight: bold;
    text-decoration: none;
  }
`