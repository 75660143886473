import styled from '@emotion/styled'
import { colors, fonts } from '../variables'

export const Banner = styled.div`
  text-align: center;
  margin: 0 0 50px;
  position: relative;
  
  h1 {
    background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.8));
    bottom: 0;
    color: white;
    font-family: ${fonts.tertiary};
    font-size: 9.5rem;
    font-weight: 300;
    margin-bottom: .2rem;
    padding: 3rem 0;
    position: absolute;
    text-transform: uppercase;
    left: 0;
    right: 0;
    text-align: center;
    width: 100%;
  }

  p {
    color: ${colors.primary.light};
    font-family: ${fonts.seconaray};
  }

  img {
    height: auto;
    margin: 0 auto;
    width: 100%;
  }
`