import styled from '@emotion/styled'
import { colors, breakpoints} from '../variables';

export const WineCard = styled.div`
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin: 0 auto 2rem;
  padding: 3rem;
  text-align: center;
  width: 100%;
  
  @media (${breakpoints.small}) {
    margin: 0 2rem 3rem;
    width: 32rem;
  }

  &:hover {
    box-shadow: 0 0 15px 0 rgba(0,0,0,.1);

    img {
      transform: translateY(-2%);
    }
  }

  img {
    background-color: ${colors.primary.light};
    border: none;
    border-radius: 4px;
    height: 20rem;
    margin: 0 auto 2rem;
    transition: transform .5s ease-in-out;
    width: auto;
    
    @media (${breakpoints.small}) {
      height: 30rem;
    }
  }

  h4  {
    color: ${colors.primary.base};
    font-size: 2.8rem;
    letter-spacing: .1ex;
    margin-bottom: .5rem;
    text-transform: uppercase;
  }

  p {
    color: ${colors.primary.light};
    font-size: 1.3rem;
    letter-spacing: .1ex;
    text-transform: uppercase;
  }
`;