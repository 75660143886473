export const colors = {
  primary: {
    base: '#59151D',
    light: '#AB9EA5',
  },
  grey: {
    base: '#67686d'
  }
}

export const fonts = {
  primary: '"Open Sans", sans-serif',
  seconaray: '"Montserrat", sans-serif',
  tertiary: '"Teko", Helvetica Neue, sans-serif',
}

export const breakpoints = {
  small: 'min-width: 420px',
  container: 'min-width: 1128px'
}