import { css } from 'emotion'
import {breakpoints} from '../variables'

export const boxWrap = css`
  background-color: white;
  box-shadow: 0 16px 48px #E7EBF6;
  margin: 0 auto;
  max-width: 1400px;
  min-height: 100vh;
  padding: 0;
`

export const container = css`
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1128px;
  padding: 3rem 2rem;

  @media (${breakpoints.container}) {
    padding: 3rem 0;
  }
`