import React from 'react'
import { Link } from 'gatsby'
import { Wrap, Head, Nav } from './header.styles'
import logo from '../../images/logo.png'
import Icon from '../icons/icons'

const Header = ({ siteTitle }) => (
  <Wrap>
    <Head>
      <Link
        to="/"
        style={{
          color: 'white',
          textDecoration: 'none',
        }}
      >
        <img alt={siteTitle} src={logo} />
      </Link>
      <Nav>
        <a href="https://www.instagram.com/bodegainiestagt/" target="_blank">
          <Icon icon="instagram" />
        </a>
        <a href="https://www.facebook.com/BodegaIniestaGT" target="_blank">
          <Icon icon="facebook" />
        </a>
        {/* <Link to="/bodega/">
          BODEGA
        </Link>
        <Link to="/vinos/">
          VINOS
        </Link>
        <Link to="/contacto/">
          CONTACTO
        </Link> */}
      </Nav>
    </Head>
  </Wrap>
)

export default Header
